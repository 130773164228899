import React, {useState, useEffect} from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import {useSelector} from "react-redux";
import {notification} from "antd";
import './PromotionsPage.scss';

const AddPromotionPage = () => {
  const {token} = useSelector(state => state)
  const [title, setTitle] = useState('');
  const [descr, setDescr] = useState('');
  const [pageContent, setPageContent] = useState([]);

  const getPromotions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: "Promotions" }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      setPageContent(responseData.content.ru);
      console.log(responseData.content.ru);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getPromotions();
  }, []);


  const onSubmit = async () => {
    try {
      const requestBody = {
        page: "Promotions",
        content: {
          ru: [
            ...pageContent,
            { title: title, descr: descr, id: pageContent.length + 1 },
          ],
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      console.log(responseData);
      notification.success({ message: "Акция успешно добавлена" });
      setTitle("");
      setDescr("");
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  return (
    <div style={{ paddingTop: "110px", paddingLeft: "20px", width: "100%" }}>
      <Button text={"Вернуться"} onClick={() => window.history.back()} />
      <div
        style={{
          background: "white",
          padding: "24px 16px",
          borderRadius: "12px",
          width: "800px",
          margin: "20px auto 0",
          display: "block",
        }}
      >
        <h2 style={{ fontSize: "20px", textAlign: "center" }}>
          Добавление акции
        </h2>
        <div style={{
                    display: 'flex',
                    gap: '20px',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    width: '75%',
                    margin: '20px auto 0'
                }}>
                  <Input style={{border: '1.5px solid rgba(176, 194, 255, .6)', borderRadius: '13px'}} placeholder={'Заголовок акции'} maskType={String} value={title} onChange={e => setTitle(e.target.value)} />
                  <TextArea className="QAPage__textarea" placeholder="Описание акции" value={descr} onChange={e => setDescr(e.target.value)} rows={6}></TextArea>
                  <div style={{ margin: '0 auto'}}>
                        <Button text={'Создать'} onClick={onSubmit}/>
                  </div>
              </div>
      </div>
    </div>
  );
}

export default AddPromotionPage