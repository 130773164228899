const weektimes = [
  {
      name: 'ПН',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'ВТ',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'СР',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'ЧТ',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'ПТ',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'СБ',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: ''
  },
  {
      name: 'ВС',
      values: [
          {
              start: '08:00',
              end: '23:00'
          }
      ],
      rest: 'Выходной'
  },
]

export default weektimes;