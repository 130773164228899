import React from 'react'

const OrgsIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.2371 22.75H8.23707C3.61707 22.75 2.75707 20.6 2.53707 18.51L1.78707 10.5C1.67707 9.45001 1.64707 7.90001 2.68707 6.74001C3.58707 5.74001 5.07707 5.26001 7.23707 5.26001H17.2371C19.4071 5.26001 20.8971 5.75001 21.7871 6.74001C22.8271 7.90001 22.7971 9.45001 22.6871 10.51L21.9371 18.5C21.7171 20.6 20.8571 22.75 16.2371 22.75ZM7.23707 6.75001C5.54707 6.75001 4.38707 7.08001 3.79707 7.74001C3.30707 8.28001 3.14707 9.11001 3.27707 10.35L4.02707 18.36C4.19707 19.94 4.62707 21.25 8.23707 21.25H16.2371C19.8371 21.25 20.2771 19.94 20.4471 18.35L21.1971 10.36C21.3271 9.11001 21.1671 8.28001 20.6771 7.74001C20.0871 7.08001 18.9271 6.75001 17.2371 6.75001H7.23707Z" fill="#CCCCCC"/>
<path d="M16.2371 6.75C15.8271 6.75 15.4871 6.41 15.4871 6V5.2C15.4871 3.42 15.4871 2.75 13.0371 2.75H11.4371C8.98708 2.75 8.98708 3.42 8.98708 5.2V6C8.98708 6.41 8.64708 6.75 8.23708 6.75C7.82708 6.75 7.48708 6.41 7.48708 6V5.2C7.48708 3.44 7.48708 1.25 11.4371 1.25H13.0371C16.9871 1.25 16.9871 3.44 16.9871 5.2V6C16.9871 6.41 16.6471 6.75 16.2371 6.75Z" fill="#CCCCCC"/>
<path d="M12.2371 16.75C9.48708 16.75 9.48708 15.05 9.48708 14.03V13C9.48708 11.59 9.82708 11.25 11.2371 11.25H13.2371C14.6471 11.25 14.9871 11.59 14.9871 13V14C14.9871 15.04 14.9871 16.75 12.2371 16.75ZM10.9871 12.75C10.9871 12.83 10.9871 12.92 10.9871 13V14.03C10.9871 15.06 10.9871 15.25 12.2371 15.25C13.4871 15.25 13.4871 15.09 13.4871 14.02V13C13.4871 12.92 13.4871 12.83 13.4871 12.75C13.4071 12.75 13.3171 12.75 13.2371 12.75H11.2371C11.1571 12.75 11.0671 12.75 10.9871 12.75Z" fill="#CCCCCC"/>
<path d="M14.2371 14.77C13.8671 14.77 13.5371 14.49 13.4971 14.11C13.4471 13.7 13.7371 13.32 14.1471 13.27C16.7871 12.94 19.3171 11.94 21.4471 10.39C21.7771 10.14 22.2471 10.22 22.4971 10.56C22.7371 10.89 22.6671 11.36 22.3271 11.61C19.9871 13.31 17.2271 14.4 14.3271 14.77C14.2971 14.77 14.2671 14.77 14.2371 14.77Z" fill="#CCCCCC"/>
<path d="M10.2371 14.7799C10.2071 14.7799 10.1771 14.7799 10.1471 14.7799C7.40708 14.4699 4.73708 13.4699 2.42708 11.8899C2.08708 11.6599 1.99708 11.1899 2.22708 10.8499C2.45708 10.5099 2.92708 10.4199 3.26708 10.6499C5.37708 12.0899 7.80708 12.9999 10.3071 13.2899C10.7171 13.3399 11.0171 13.7099 10.9671 14.1199C10.9371 14.4999 10.6171 14.7799 10.2371 14.7799Z" fill="#CCCCCC"/>
</svg>
  )
}

export default OrgsIcon