import React from 'react'

const AnalyticsIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.11708 18.9001C6.70708 18.9001 6.36708 18.5601 6.36708 18.1501V16.0801C6.36708 15.6701 6.70708 15.3301 7.11708 15.3301C7.52708 15.3301 7.86708 15.6701 7.86708 16.0801V18.1501C7.86708 18.5701 7.52708 18.9001 7.11708 18.9001Z" fill="#CCCCCC"/>
<path d="M12.2371 18.9C11.8271 18.9 11.4871 18.56 11.4871 18.15V14C11.4871 13.59 11.8271 13.25 12.2371 13.25C12.6471 13.25 12.9871 13.59 12.9871 14V18.15C12.9871 18.57 12.6471 18.9 12.2371 18.9Z" fill="#CCCCCC"/>
<path d="M17.3571 18.8999C16.9471 18.8999 16.6071 18.5599 16.6071 18.1499V11.9299C16.6071 11.5199 16.9471 11.1799 17.3571 11.1799C17.7671 11.1799 18.1071 11.5199 18.1071 11.9299V18.1499C18.1071 18.5699 17.7771 18.8999 17.3571 18.8999Z" fill="#CCCCCC"/>
<path d="M7.11707 13.1799C6.77707 13.1799 6.47707 12.9499 6.38707 12.6099C6.28707 12.2099 6.52707 11.7999 6.93707 11.6999C10.6171 10.7799 13.8571 8.76992 16.3271 5.89992L16.7871 5.35992C17.0571 5.04992 17.5271 5.00992 17.8471 5.27992C18.1571 5.54992 18.1971 6.01992 17.9271 6.33992L17.4671 6.87992C14.7971 9.99992 11.2771 12.1699 7.29707 13.1599C7.23707 13.1799 7.17707 13.1799 7.11707 13.1799Z" fill="#CCCCCC"/>
<path d="M17.3571 9.5201C16.9471 9.5201 16.6071 9.1801 16.6071 8.7701V6.6001H14.4271C14.0171 6.6001 13.6771 6.2601 13.6771 5.8501C13.6771 5.4401 14.0171 5.1001 14.4271 5.1001H17.3571C17.7671 5.1001 18.1071 5.4401 18.1071 5.8501V8.7801C18.1071 9.1901 17.7771 9.5201 17.3571 9.5201Z" fill="#CCCCCC"/>
<path d="M15.2371 22.75H9.23708C3.80708 22.75 1.48708 20.43 1.48708 15V9C1.48708 3.57 3.80708 1.25 9.23708 1.25H15.2371C20.6671 1.25 22.9871 3.57 22.9871 9V15C22.9871 20.43 20.6671 22.75 15.2371 22.75ZM9.23708 2.75C4.62708 2.75 2.98708 4.39 2.98708 9V15C2.98708 19.61 4.62708 21.25 9.23708 21.25H15.2371C19.8471 21.25 21.4871 19.61 21.4871 15V9C21.4871 4.39 19.8471 2.75 15.2371 2.75H9.23708Z" fill="#CCCCCC"/>
</svg>

  )
}

export default AnalyticsIcon