import React from 'react'

const CatalogIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.617 22.75H3.477C2.527 22.75 1.64701 22.31 1.07701 21.54C0.497008 20.76 0.327016 19.78 0.617016 18.85L4.82701 5.31995C5.20701 4.05995 6.36702 3.19995 7.68702 3.19995H19.987C21.197 3.19995 22.287 3.92004 22.747 5.04004C22.997 5.62004 23.047 6.28005 22.897 6.93005L19.527 20.46C19.207 21.81 18.007 22.75 16.617 22.75ZM7.697 4.70996C7.047 4.70996 6.45701 5.14002 6.26701 5.77002L2.05702 19.3C1.91702 19.77 1.99701 20.26 2.29701 20.66C2.57701 21.04 3.01701 21.26 3.48701 21.26H16.627C17.317 21.26 17.917 20.79 18.077 20.12L21.447 6.57996C21.527 6.24996 21.507 5.92 21.377 5.63C21.137 5.06 20.607 4.69995 19.997 4.69995H7.697V4.70996Z" fill="#CCCCCC"/>
<path d="M21.0171 22.75H16.2371C15.8271 22.75 15.4871 22.41 15.4871 22C15.4871 21.59 15.8271 21.25 16.2371 21.25H21.0171C21.4271 21.25 21.8071 21.08 22.0871 20.78C22.3671 20.48 22.5071 20.08 22.4771 19.67L21.4871 6.05002C21.4571 5.64002 21.7671 5.27997 22.1771 5.24997C22.5871 5.22997 22.9471 5.52991 22.9771 5.93991L23.9671 19.56C24.0271 20.38 23.7371 21.2 23.1771 21.8C22.6271 22.41 21.8371 22.75 21.0171 22.75Z" fill="#CCCCCC"/>
<path d="M9.91684 7.12996C9.85684 7.12996 9.79685 7.11994 9.73685 7.10994C9.33685 7.00994 9.08686 6.6099 9.18686 6.1999L10.2268 1.87996C10.3268 1.47996 10.7268 1.22991 11.1368 1.32991C11.5368 1.42991 11.7869 1.82994 11.6869 2.23994L10.6469 6.56001C10.5669 6.90001 10.2568 7.12996 9.91684 7.12996Z" fill="#CCCCCC"/>
<path d="M16.617 7.13999C16.567 7.13999 16.507 7.13997 16.457 7.11997C16.057 7.02997 15.797 6.62995 15.877 6.22995L16.817 1.88999C16.907 1.47999 17.307 1.23003 17.707 1.31003C18.107 1.39003 18.367 1.79992 18.287 2.19992L17.347 6.54001C17.277 6.90001 16.967 7.13999 16.617 7.13999Z" fill="#CCCCCC"/>
<path d="M15.9373 12.75H7.93727C7.52727 12.75 7.18727 12.41 7.18727 12C7.18727 11.59 7.52727 11.25 7.93727 11.25H15.9373C16.3473 11.25 16.6873 11.59 16.6873 12C16.6873 12.41 16.3473 12.75 15.9373 12.75Z" fill="#CCCCCC"/>
<path d="M14.9373 16.75H6.93727C6.52727 16.75 6.18727 16.41 6.18727 16C6.18727 15.59 6.52727 15.25 6.93727 15.25H14.9373C15.3473 15.25 15.6873 15.59 15.6873 16C15.6873 16.41 15.3473 16.75 14.9373 16.75Z" fill="#CCCCCC"/>
</svg>

  )
}

export default CatalogIcon