import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import "./ArticlesPage.scss";
import {Upload} from 'antd';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import { useNavigate } from 'react-router-dom';

Quill.register('modules/htmlEditButton', htmlEditButton);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

  const asyncRequest = async ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

const AddArticlePage = () => {
  const nav = useNavigate();
  const { token } = useSelector((state) => state);
  const [title, setTitle] = useState("");
  const [descr, setDescr] = useState("");
  const [image, setImage] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [pageContent, setPageContent] = useState([]);
  const [editorImages, setEditorImages] = useState([]);

  const handleUpload = async (info) => {
    console.log(info.file.status);
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempImage(blobImg);
      setImage(base64);
    }
  };

  const getArticles = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: "Articles" }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      setPageContent(responseData.content.ru);
      console.log(responseData.content.ru);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getArticles();
  }, []);

  const onSubmit = async () => {
    try {
      const d = new FormData();

      d.append("status", 1);
      d.append("entity_id", 2);
      d.append("image", image);
      d.append("name", title);
      d.append("html", descr);
      d.append("id", null);
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/news/set_news`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: d,
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      nav('/articles')
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    htmlEditButton: {},
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];


  return (
    <div style={{ paddingTop: "110px", paddingLeft: "20px", paddingBottom: '40px', width: "100%" }}>
      <div className="ArticlePage__header">
        <Button text={"Вернуться"} onClick={() => window.history.back()} />
        <Button text={"Опубликовать"} onClick={onSubmit} />
      </div>
      <div
        style={{
          background: "white",
          padding: "24px 16px",
          borderRadius: "12px",
          width: "800px",
          margin: "20px auto 0",
          display: "block",
        }}
      >
        <h2 style={{ fontSize: "20px", textAlign: "center" }}>
          Добавление акции
        </h2>
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            flexDirection: "column",
            width: "75%",
            margin: "20px auto 0",
          }}
        >
          {tempImage ? (
                    <div className="banners__pic-item">
                      <img src={tempImage} alt="" />
                      <div className="baners__pic-item--backdrop">
                        <Upload
                          className="baners__pic-item--new"
                          showUploadList={false}
                          customRequest={asyncRequest}
                          listType="picture-card"
                          onChange={handleUpload}
                        >
                          <p>Загрузить другую картинку</p>
                        </Upload>
                      </div>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            placeholder={"Заголовок статьи"}
            maskType={String}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {/* <TextArea
            className="ArticlePage__textarea"
            placeholder="Описание статьи"
            value={descr}
            onChange={(e) => setDescr(e.target.value)}
            rows={6}
          ></TextArea> */}
          <ReactQuill
            value={descr}
            onChange={setDescr}
            style={{ height: "200px", color: "black" }}
            formats={formats}
            modules={modules}
          />
          <div>
            <h1 style={{ marginTop: "50px", fontSize: "20px", padding: 0 }}>
              Предпросмотр:
            </h1>
            <hr style={{ marginTop: "5px" }} />
            <div className="previewText">{parse(descr)}</div>
          </div>
          {/* <div style={{ margin: "50px auto 0" }}>
            <Button text={"Создать"} onClick={onSubmit} />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AddArticlePage;
