


export const DeliveryPage = () => {

    return (
        <>
        Delivery
        </>
    )
}