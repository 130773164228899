import React from 'react'

const StoriesIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.0471 20.18C15.7871 20.18 15.5171 20.17 15.2271 20.14C14.7071 20.1 14.1171 20 13.5071 19.85L11.8271 19.45C7.21707 18.36 5.70707 15.92 6.78707 11.32L7.76707 7.13001C7.98707 6.18002 8.24707 5.41002 8.56707 4.77002C10.2871 1.22002 13.5771 1.54001 15.9171 2.09001L17.5871 2.48001C19.9271 3.03001 21.4071 3.90001 22.2371 5.23002C23.0571 6.56002 23.1871 8.27001 22.6371 10.61L21.6571 14.79C20.7971 18.45 19.0071 20.18 16.0471 20.18ZM13.3571 3.25001C11.6871 3.25001 10.6271 3.94002 9.91707 5.42002C9.65707 5.96002 9.42707 6.63001 9.22707 7.47001L8.24707 11.66C7.35707 15.44 8.38707 17.09 12.1671 17.99L13.8471 18.39C14.3871 18.52 14.8971 18.6 15.3571 18.64C18.0771 18.91 19.4271 17.72 20.1871 14.45L21.1671 10.27C21.6171 8.34002 21.5571 6.99002 20.9571 6.02001C20.3571 5.05001 19.1771 4.39002 17.2371 3.94002L15.5671 3.55001C14.7371 3.35001 13.9971 3.25001 13.3571 3.25001Z" fill="#CCCCCC"/>
<path d="M8.56708 22.2499C5.99708 22.2499 4.35708 20.7099 3.30708 17.4599L2.02708 13.5099C0.607082 9.10993 1.87708 6.62993 6.25708 5.20993L7.83708 4.69993C8.35708 4.53993 8.74708 4.42993 9.09708 4.36993C9.38708 4.30993 9.66708 4.41993 9.83708 4.64993C10.0071 4.87993 10.0371 5.17993 9.91708 5.43993C9.65708 5.96993 9.42708 6.63993 9.23708 7.47993L8.25708 11.6699C7.36708 15.4499 8.39708 17.0999 12.1771 17.9999L13.8571 18.3999C14.3971 18.5299 14.9071 18.6099 15.3671 18.6499C15.6871 18.6799 15.9471 18.8999 16.0371 19.2099C16.1171 19.5199 15.9971 19.8399 15.7371 20.0199C15.0771 20.4699 14.2471 20.8499 13.1971 21.1899L11.6171 21.7099C10.4671 22.0699 9.46708 22.2499 8.56708 22.2499ZM8.01708 6.21993L6.72708 6.63993C3.15708 7.78993 2.30708 9.46993 3.45708 13.0499L4.73708 16.9999C5.89708 20.5699 7.57708 21.4299 11.1471 20.2799L12.7271 19.7599C12.7871 19.7399 12.8371 19.7199 12.8971 19.6999L11.8371 19.4499C7.22708 18.3599 5.71708 15.9199 6.79708 11.3199L7.77708 7.12993C7.84708 6.80993 7.92708 6.49993 8.01708 6.21993Z" fill="#CCCCCC"/>
<path d="M17.7271 10.51C17.6671 10.51 17.6071 10.5 17.5371 10.49L12.6871 9.26002C12.2871 9.16002 12.0471 8.75002 12.1471 8.35002C12.2471 7.95002 12.6571 7.71002 13.0571 7.81002L17.9071 9.04002C18.3071 9.14002 18.5471 9.55002 18.4471 9.95002C18.3671 10.28 18.0571 10.51 17.7271 10.51Z" fill="#CCCCCC"/>
<path d="M14.7971 13.8899C14.7371 13.8899 14.6771 13.8799 14.6071 13.8699L11.6971 13.1299C11.2971 13.0299 11.0571 12.6199 11.1571 12.2199C11.2571 11.8199 11.6671 11.5799 12.0671 11.6799L14.9771 12.4199C15.3771 12.5199 15.6171 12.9299 15.5171 13.3299C15.4371 13.6699 15.1371 13.8899 14.7971 13.8899Z" fill="#CCCCCC"/>
</svg>

  )
}

export default StoriesIcon