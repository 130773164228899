import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";
import "./PayDeliveryPage.scss";
import { Select } from "antd";
import { id } from "date-fns/locale";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import { nanoid } from "nanoid";
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import { useNavigate } from 'react-router-dom';

Quill.register('modules/htmlEditButton', htmlEditButton);


const AddConditions = () => {
  const nav = useNavigate();
  const { token } = useSelector((state) => state);
  const [title, setTitle] = useState("");
  const [descr, setDescr] = useState("");
  const [block, setBlock] = useState("");
  const [pageContent, setPageContent] = useState([]);
  const [lastContent, setLastContent] = useState([]);
  const [conditionsContent, setConditionsContent] = useState([]);


  const handleSelectChange = (value) => {
    setBlock(value);
  };

  const getDelivery = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: "ShippingAndPayment" }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      // console.log(responseData.content.ru)
      setLastContent(responseData.content.ru.slice(0, 13));
      // console.log(responseData.content.ru.slice(0, 13))
      // console.log(responseData.content.ru[13].qa)
      setPageContent(responseData.content.ru[13].qa);
      setConditionsContent(responseData.content.ru[13].conditions);
      // console.log(responseData.content.ru[13].qa);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getDelivery();
  }, []);


  const onSubmit = async () => {
    try {
      const requestBody = {
        page: "ShippingAndPayment",
        content: {
          ru: [...lastContent, {qa: [...pageContent], conditions: [...conditionsContent, {question: title, answer: descr, id: nanoid()}]}],
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      nav('/pay-delivery')
      // console.log(responseData);
      // notification.success({ message: "Условие доставки успешно добавлено" });
      // setTitle("");
      // setDescr("");
      // getDelivery();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    htmlEditButton: {}
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <div style={{ paddingTop: "110px", paddingLeft: "20px", width: "100%" }}>
      <Button text={"Вернуться"} onClick={() => window.history.back()} />
      <div
        style={{
          background: "white",
          padding: "24px 16px",
          borderRadius: "12px",
          width: "800px",
          margin: "20px auto 0",
          display: "block",
        }}
      >
        <h2 style={{ fontSize: "20px", textAlign: "center" }}>Добавление</h2>
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            flexDirection: "column",
            width: "75%",
            margin: "20px auto 0",
          }}
        >
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            placeholder={"Заголовок"}
            maskType={String}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          {/* <TextArea
            className="DeliveryPage__textarea"
            placeholder="Текст"
            value={descr}
            onChange={(e) => setDescr(e.target.value)}
            rows={6}
          ></TextArea> */}
          <ReactQuill
            value={descr}
            onChange={setDescr}
            style={{ height: "200px", color: "black" }}
            formats={formats}
            modules={modules}
          />
          {/* <Select
            className="DeliveryPage__select"
            showSearch
            placeholder="Где отображать"
            optionFilterProp="children"
            onChange={handleSelectChange}
            options={[
              {
                value: "0",
                label: "Верхний блок",
              },
              {
                value: "1",
                label: "Средний блок",
              },
              {
                value: "2",
                label: "Нижний блок",
              },
            ]}
          /> */}
          <div style={{ margin: "70px auto 0" }}>
            <Button text={"Создать"} onClick={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddConditions;
