const SaveIcon = ({size, color}) => {
    return (
        <div className="icon-gs" style={{height: size, width: size}}>
            <svg width={size} height={size} viewBox="0 0 169 169" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M143.177 42.2108C145.191 44.2237 146.788 46.6151 147.874 49.2474C148.961 51.8797 149.517 54.7009 149.509 57.5486V127.906C149.509 133.66 147.228 139.179 143.166 143.254C139.104 147.329 133.593 149.628 127.839 149.646H41.0181C35.258 149.609 29.7464 147.295 25.6865 143.208C21.6266 139.122 19.348 133.596 19.3481 127.835V41.1554C19.3481 35.4082 21.6312 29.8963 25.6951 25.8324C29.7591 21.7685 35.2709 19.4854 41.0181 19.4854H111.375C114.223 19.4777 117.044 20.0332 119.677 21.1199C122.309 22.2066 124.7 23.8031 126.713 25.8176L143.177 42.2108Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M55.5125 98.8508H113.346C117.171 98.8508 120.84 100.37 123.545 103.075C126.25 105.78 127.769 109.449 127.769 113.274V149.438H41.019V113.344C41.0097 111.438 41.3782 109.55 42.1032 107.787C42.8283 106.024 43.8955 104.423 45.2433 103.075C46.591 101.727 48.1924 100.66 49.9551 99.935C51.7177 99.21 53.6066 98.8414 55.5125 98.8508V98.8508Z" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M41.0188 19.417V50.5149C41.0188 53.7617 42.3086 56.8755 44.6044 59.1714C46.9003 61.4672 50.0141 62.757 53.2609 62.757H101.104C104.351 62.757 107.464 61.4672 109.76 59.1714C112.056 56.8755 113.346 53.7617 113.346 50.5149V20.1206" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M91.6755 37.4287V44.6755" stroke={color} strokeWidth="10.5536" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>

        </div>
    )
}

export default SaveIcon;