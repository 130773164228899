const actionsOnBtn = [
    {
        value: 'Открыть категорию',
        ID: 1,
        pl: 'Выбрать категорию'
    },
    {
        value: 'Не показывать кнопку',
        ID: 0,
    },
    {
        value: 'Открыть подкатегорию',
        ID: 2,
        pl: 'Выбрать подкатегорию'
    },
    {
        value: 'Открыть блюдо',
        ID: 3,
        pl: 'Выбрать блюдо'
    },
    {
        value: 'Открыть внешнюю ссылку',
        ID: 4,
        pl: 'Ссылка'
    }
]

export default actionsOnBtn;