import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./DeliveryRules.scss";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Pl from "../../components/Pl/Pl";
import { Modal } from "antd";
import { BsTrash } from "react-icons/bs";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";
import parse from "html-react-parser";
import "react-quill/dist/quill.snow.css";
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';

Quill.register('modules/htmlEditButton', htmlEditButton);


const DeliveryRules = () => {
  const { token } = useSelector((state) => state);
  const navigate = useNavigate();
  const [isViewVacancyModalOpen, setIsViewVacancyModalOpen] = useState(false);
  const [isEditVacancyModalOpen, setIsEditVacancyModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [pageContent, setPageContent] = useState([]);
  const showViewVacancyModal = (html) => {
    const replaceNbspWithBr = (htmlString) => {
      return htmlString.replace(/(&nbsp;)+/g, '<br/>');
    };
    setModalContent(replaceNbspWithBr(html))
    setIsViewVacancyModalOpen(true);
  };
  const handleViewVacancyModalCancel = () => {
    setIsViewVacancyModalOpen(false);
  };
  const showEditVacancyModal = () => {
    setIsEditVacancyModalOpen(true);
  };
  const handleEditVacancyModalCancel = () => {
    setIsEditVacancyModalOpen(false);
  };

  const getRule = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: "DeliveryRules" }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      setPageContent(responseData.content.ru);
      console.log(responseData.content.ru);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getRule();
  }, []);

  const editRule = async (id) => {
    try {
      const requestBody = {
        page: "DeliveryRules",
        content: {
          ru: {html: modalContent},
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({ message: "Контент успешно отредактирован" });
      handleEditVacancyModalCancel();
      getRule();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };


  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    htmlEditButton: {}
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="VacanciesPage__body pageBody-content">
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr", gap: "30px" }}
          >
            {/* {pageContent
              ? pageContent.map((item) => (
                  <Pl
                    style={{
                      width: "100%",
                      height: "60px",
                      backgroundColor: "#fff",
                    }}
                    text={item.title}
                    onClick={() =>
                      showViewVacancyModal(item.title, item.descr, item.id)
                    }
                  ></Pl>
                ))
              : null} */}
              <Pl
                    style={{
                      width: "100%",
                      height: "60px",
                      backgroundColor: "#fff",
                    }}
                    text={'Контент'}
                    onClick={() =>
                      showViewVacancyModal(pageContent.html)
                    }
                  ></Pl>
          </div>
          <Modal
            open={isViewVacancyModalOpen}
            title={'Контент'}
            onCancel={handleViewVacancyModalCancel}
            footer={[]}
            width={580}
          >
            <p className="VacanciesPage__modal-descr">
              {modalContent ? parse(modalContent) : null}
            </p>
            <div className="VacanciesPage__modal-wrapper">
              <Button
                key="back"
                onClick={showEditVacancyModal}
                text={"Редактировать"}
              />
            </div>
            <Modal
              open={isEditVacancyModalOpen}
              title="Редактировать вакансию"
              onCancel={handleEditVacancyModalCancel}
              footer={[]}
              width={880}
            >
              <div className="VacanciesPage__item-label">Контент</div>
              <ReactQuill
                value={modalContent ? modalContent : null}
                onChange={(e) => {
                  setModalContent(e);
                }}
                style={{ height: "200px", color: "black" }}
                formats={formats}
                modules={modules}
              />
              <div
                style={{ marginTop: "90px" }}
                className="VacanciesPage__modal-wrapper"
              >
                <Button
                  key="back"
                  onClick={handleEditVacancyModalCancel}
                  text={"Отмена"}
                  variant={"danger"}
                />
                <Button
                  key="back"
                  onClick={() => editRule()}
                  text={"Сохранить"}
                />
              </div>
            </Modal>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default DeliveryRules;
