import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./Accessibility.scss";
import Button from "../../components/Button/Button";
import Pl from "../../components/Pl/Pl";
import { Modal, Checkbox } from "antd";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";

const Accessibility = () => {
  const { token } = useSelector((state) => state);
  const [pageContent, setPageContent] = useState({});
  const [title, setTitle] = useState('');
  const [descr, setDescr] = useState('');
  const [access, setAccess] = useState('');

  const getAccess = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ page: "SiteAccessibility" }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      setPageContent(responseData.content.ru);
      setTitle(responseData.content.ru.title);
      setDescr(responseData.content.ru.descr);
      if (responseData.content.ru.siteAccessibility == 1) {
        setAccess(true);
      } else {
        setAccess(false);
      }
      console.log(responseData.content);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const handleEditAccess = async (id) => {
    try {
      const requestBody = {
        page: "SiteAccessibility",
        content: {
          ru: {
            title: title,
            descr: descr,
            siteAccessibility: access
          }
        },
      };

      const response = await fetch(
        `${process.env.REACT_APP_HOST}/NewAdminPanel/pages/getPages`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({message: 'Успешно отредактировано'})
      getAccess();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getAccess();
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="VacanciesPage__body pageBody-content">
          <div className="VacanciesPage__item">
            <div className="VacanciesPage__item-label">Заголовок</div>
            <Input
              style={{
                border: "1.5px solid rgba(176, 194, 255, .6)",
                borderRadius: "13px",
              }}
              maskType={String}
              shadow
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
            />
          </div>
          <div className="VacanciesPage__item">
            <div className="VacanciesPage__item-label">Описание</div>
            <TextArea
              className="vacanciesPage__textarea"
              value={descr}
              onChange={(e) => {setDescr(e.target.value)}}
              rows={4}
            ></TextArea>
          </div>
          <div className="VacanciesPage__item">
            <div className="VacanciesPage__item-label">Доступность</div>
            <input
              type="checkbox"
              onChange={(e) => {
                console.log(e.target.checked)
                if (e.target.checked) { 
                  setAccess(1)
                } else {
                  setAccess(0)
                }
              }}
              checked={access}
              style={{ width: "100px", cursor: "pointer" }}
            />
          </div>
          <Button
            key="back"
            onClick={() => handleEditAccess()}
            text={"Сохранить"}
            styles={{marginLeft: 'auto', marginTop: '50px'}}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Accessibility;
