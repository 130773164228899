let orderBy = []

if (
    window.location.origin === 'https://mama.gscore.ru' || 
    window.location.origin === 'https://pizzburg.gscore.ru' || 
    window.location.origin === 'https://mamasochi.gscore.ru' || 
    window.location.origin === 'https://kekeev.gscore.ru' || 
    window.location.origin === 'https://ident.gscore.ru' || 
    window.location.origin === 'https://test1.gscore.ru' || 
    window.location.origin === 'https://papapanda.gscore.ru' || 
    window.location.origin === 'https://paulbaker.gscore.ru'|| 
    window.location.origin === 'https://darvin.gscore.ru'|| 
    window.location.origin === 'https://iiko.gscore.ru'|| 
    window.location.origin === 'http://localhost:3000') {
    orderBy = [
        {
            name: 'ID',
            label: 'ID'
        },
        {
            name: 'UserName',
            label: 'Имя'
        },
        {
            name: 'Operator',
            label: 'Оператор'
        },
        {
            name: 'OperatorStatus',
            label: 'Статус оператора'
        },
        {
            name: 'Status',
            label: 'Статус'
        },
        {
            name: 'SalePrice',
            label:'Сумма'
        },
        {
            name: 'DeliveryType',
            label: 'Тип доставки'
        },
        {
            name: 'PayType',
            label: 'Тип оплаты'
        },
        {
            name: 'DateCreated',
            label: 'Дата заказа'
        },
        {
            label: 'Статус оплаты'
        },
        {   
            label: 'Адрес'
        }
    ]
} else {
    orderBy = [{
        name: 'ID',
        label: 'ID'
    },
    {
        name: 'UserName',
        label: 'Имя'
    },
    {
        name: 'Status',
        label: 'Статус'
    },
    {
        name: 'SalePrice',
        label:'Сумма'
    },
    {
        name: 'DeliveryType',
        label: 'Тип доставки'
    },
    {
        name: 'PayType',
        label: 'Тип оплаты'
    },
    {
        name: 'DateCreated',
        label: 'Дата заказа'
    },
    {
        label: 'Статус оплаты'
    },
    {   
        label: 'Адрес'
    }]
}

export default orderBy;