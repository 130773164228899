import './GridToggle.scss';
import {BsGrid3X2Gap, BsGrid3X2} from 'react-icons/bs';
import {Tooltip, notification, Spin} from 'antd';
import Button from '../Button/Button';
import { LoadingOutlined } from '@ant-design/icons';
import { useState } from 'react';
import {useSelector} from "react-redux";
import checkDom from '../../funcs/checkDom';






const GridToggle = ({
    selectBig,
    selectSmall,
    isShownCopyButton
}) => {
    const {token} = useSelector(state => state)
    const [isCopyButtonLoading, setIsCopyButtonLoading] = useState(false);
 
    const handleCopyButtonClick = async () => {
        setIsCopyButtonLoading(true);
        const r = await fetch(`${process.env.REACT_APP_HOST}/NewAdminPanel/catalog/copyCatalogData`, {
            method: 'POST',
            headers: {
                // "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': `Bearer ${token}`,
            }
        })
        const rj = await r.json()


        if (rj.error == 0) {
            setIsCopyButtonLoading(false);
            notification.success({
                message: 'Успешно скопировано',
                placement: 'top'
            });
        } else {
            setIsCopyButtonLoading(false);
            notification.error({message: 'Ошибка при копировании', placement: 'top'})
        }
    }

    return (
        <div className="GridToggle">
            <div className="GridToggle__in">
                
                {isShownCopyButton ? 
                        checkDom('https://tigrus.gscore.ru', (
                            <button onClick={handleCopyButtonClick} className='cat__button'>
                        {isCopyButtonLoading ?
                        <Spin className='cat__button--loading' indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> : null}
                        Скопировать в приложение</button> 
                        ))
                    : null}
                <Tooltip
                    placement='bottom'
                    title='Маленькие карточки'
                    >
                    <button onClick={selectSmall} className="GridToggle__item">
                        <BsGrid3X2Gap/>
                    </button>
                </Tooltip>
                <Tooltip
                    placement='bottom'
                    title='Большие карточки'
                    >
                    <button onClick={selectBig} className="GridToggle__item">
                        <BsGrid3X2/>
                    </button>
                </Tooltip>
                
            </div>
        </div>
    )
}

export default GridToggle;